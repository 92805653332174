* {
  box-sizing: border-box;
}

body {
  background: url("./img/Hero1.svg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: #485e74;
  line-height: 1.5;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 1em; 
}
paper-button {
  text-transform: none;
}
.container {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
}

ul {
  list-style: none;
  padding: 0;
}

.brand {
  text-align: center;
}

.brand span {
  color: #2d6dc1;
}

.wrapper {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.7);
  height: 700px;
  border-radius: 25px;
}

.wrapper>* {
  padding: 1em;
}

.white {
  color: white;
}

.white span {
  color: rgb(27, 50, 112);
}

.company-info {
  background: #2d6dc1;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.company-info h3,
.company-info ul {
  text-align: center;
  margin: 0 0 1rem 0;
  margin-top: 17px;
}

.checklist {
  background: #f9feff;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

/* FORM STYLES */
.checklist form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.checklist form label {
  display: block;
}

.checklist form p {
  margin: 0;
}

.checklist form .full {
  grid-column: 1 / 3;
}

.checklist form button,
.checklist form input,
.checklist form textarea,
.checklist form select {
  width: 100%;
  padding: 1em;
  border: 1px solid #2d6dc1;
}

.opt-section input {
  width: auto !important;
  margin: 6px 6px 6px 0px !important;
  float: none !important;
}

.checklist form button {
  background: #2d6dc1;
  color: white;
  border: 0;
  text-transform: uppercase;
}

.checklist form button:hover,
.checklist form button:focus {
  background: #92bde7;
  color: #fff;
  outline: 0;
  transition: background-color 2s ease-out;
}

.alert {
  text-align: center;
  padding: 10px;
  background: #79c879;
  color: #fff;
  margin-bottom: 10px;
  display: none;
}

/* LARGE SCREENS */
@media(min-width:700px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  .wrapper>* {
    padding: 2em;
  }

  .company-info h3,
  .company-info ul,
  .brand {
    text-align: left;
  }
}

.footer {
  margin-top: 50px;
  flex: 20%;
  display: flex;
  justify-content: center;
}

.footer button {
  width: 100px;
  height: 40px;
  background-color: #2d6dc1;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  margin: 5px;
}

.email-input {
  width: 300px;
  padding: 5px 10px;
}

.message {
  margin-top: 20px;
  font-size: 17px;
}

.success {
  color: blue;
}

.error {
  color: red;
}

.full input[type=radio] {
  float: left;
  clear: none;
  margin: 2px 0 0 2px;
}

a {
  padding-right: 30px;
}

.er {
  border: 1px solid red !important;
}

.er-p {
  color: red;
}

.inp-blur {
  border: 1px solid gray !important;
}


.btn-blur {
  background-color: #a9cefd !important;
}